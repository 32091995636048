import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "contact", "package", "shipping", "review", "success" ]

  open(group) {
    if(group.classList.contains("locked")) { return }

    group.classList.add("open");
  }

  close(group) {
    if(group.classList.contains("locked")) { return }

    group.classList.remove("open");
  }

  toggle(event) {
    const group = event.target.closest(".group")

    group.classList.contains("open") ? this.close(group) : this.open(group)
  }

  confirm(group) {
    const nextGroup = group.nextElementSibling

    group.classList.add("confirmed");
    nextGroup.classList.remove("locked");
    this.open(nextGroup)
    this.close(group)
    // Do we need to scroll to the next step?
  }

  closeAndReset(frame) {
    this.close(frame.closest(".group"))
    frame.src = frame.src.split("?")[0]
    frame.innerHTML = ""
    frame.removeAttribute("complete")
  }

  confirmBooking() {
    this.reviewTarget.removeAttribute("complete")
  }

  frameRendered(event) {
    const params = new URL(event.detail.fetchResponse.response.url).searchParams;

    if(params.has("order_booking_step_confirmed")) {
      this.confirm(event.target.closest(".group"))
    }

    if(params.has("reload_order_booking_review")) {
      this.reviewTarget.reload()
      this.successTarget.reload()
    }

    if(params.has("order_booking_confirmed")) {
      this.closeAndReset(this.contactTarget)
      this.closeAndReset(this.packageTarget)
      this.closeAndReset(this.shippingTarget)
    }
  }
}
